<template>
  <div>
    <form-wizard color="#039be5" :title="null" :subtitle="null" shape="square" finish-button-text="Post"
      ref="jobformwizard" back-button-text="Previous" class="mb-3 " @on-complete="formSubmitted"
      :start-index="startIndex">

      <!--add personal details tab-->
      <tab-content title="Personal Details" icon="fi fi-rr-record-vinyl" :before-change="validationPersonalForm"
        v-if="!isPerSaved">
        <validation-observer ref="personalRules" tag="form">
          <b-row class="match-height">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Personal Details
              </h5>
              <small class="text-muted">
                Enter your basic personal information.
              </small>
            </b-col>
            <b-col cols="12" class="mb-2">
              <b-media no-body>
                <b-media-aside>
                  <b-link>
                    <!-- <b-img ref="previewEl" rounded :src="avatar ? avatar : require('@/assets/images/avatars/1-small.png')"
            height="80" /> -->
                    <b-avatar ref="previewEl" size="80" square :src="avatar" variant="transparent"
                      class=" text-secondary avatar-border-2" style="border-radius: 16px !important;">
                      <u-icon v-if="!avatar" icon="fi-rr-user" size="22" />
                    </b-avatar>
                  </b-link>
                  <!--/ avatar -->
                </b-media-aside>

                <b-media-body class="mt-75 ml-75">
                  <!-- upload button -->
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                    @click="$refs.refInputEl.$el.click()">
                    Upload
                  </b-button>
                  <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain
                    @input="inputImageRenderer" />
                  <!--/ upload button -->

                  <!-- reset -->
                  <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm"
                    class="mb-75 mr-75">
                    Reset
                  </b-button>
                  <!--/ reset -->
                  <b-card-text class="text-secondary font-small-3">Allowed JPG, GIF or PNG. Max size of 800kB
                  </b-card-text>
                </b-media-body>
              </b-media>
              <!--/ media -->
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="name" vid="name" rules="required">

                  <label> Full Name<label class="text-danger">*</label></label>
                  <b-form-input v-model="fullName" name="name" placeholder="Name" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="email" vid="email" rules="required|email">

                  <label> Work E-mail Address<label class="text-danger">*</label></label>

                  <b-form-input v-model="email" name="email" placeholder="xyz@companyname.com" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="phone" vid="phone"
                  rules="required|numeric|min:10|max:10">

                  <label>Mobile Number<label class="text-danger">*</label></label>
                  <b-input-group prepend="+91">

                    <b-form-input v-model="phone" name="phone" placeholder="9876543210" disabled />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="designation" vid="designation" rules="required">

                  <label>Designation<label class="text-danger">*</label></label>
                  <b-form-input v-model="designation" name="designation" placeholder="Sr. HR Manager" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <!--add company details tab-->
      <tab-content title="Company Details" icon="fi fi-rr-record-vinyl" v-if="!isCompanySaved"
        :before-change="validationCompanyForm">
        <validation-observer ref="companyRules" tag="form">
          <b-row class="match-height">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Company Details
              </h5>
              <small class="text-muted">
                Enter the details about your company.
              </small>
            </b-col>
            <!-- Select Company -->
            <b-col cols="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company name" vid="company-name" rules="required">

                  <label> Company Name<label class="text-danger">*</label></label>
                  <vue-autosuggest :suggestions="filteredCompanies" :input-props="inputProps" @selected="onSelected"
                    :limit="10" @input="onInputChange" v-model="companyName">
                    <template slot-scope="{suggestion}">
                      <div class="d-flex align-items-center">
                        <b-avatar :src="suggestion.item.logo" square variant="transparent"
                          :text="avatarText(suggestion.item.name)" class=" text-secondary avatar-border-2"
                          style="border-radius: 16px !important;" />
                        <div class="detail ml-50">
                          <b-card-text class="mb-0">
                            {{ suggestion.item.name }}
                          </b-card-text>
                          <small class="text-muted">
                            {{ suggestion.item.CompanyWebsite }}
                          </small>
                        </div>
                      </div>
                    </template>


                  </vue-autosuggest>

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ select Company -->
            <div class="mx-2">
              <b-alert show variant="primary">
                <div class="alert-body d-flex align-middle items-center justify-content-center">
                  <div class="pt-25">
                    <u-icon class="mr-25" icon="fi-rr-exclamation" />
                  </div>
                  <small class=" font-small-4 ml-25">Introduce yourself to job seekers. Information you might want to
                    include:
                    when and why your business was founded, how much you've grown since, what your company does and the
                    types of
                    customers you serve, and your mission statement.
                  </small>
                </div>
              </b-alert>
            </div>
            <!-- bio -->
            <b-col cols="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company description" vid="company-description"
                  rules="required">

                  <label> Company Description<label class="text-danger">*</label></label>

                  <b-form-textarea v-model="companyDesc" rows="4" placeholder="About your company here..."
                    name="company description" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ bio -->

            <!-- Location -->
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company location" vid="company-location"
                  rules="required">

                  <label> Company Headquarters<label class="text-danger">*</label></label>
                  <v-select v-model="companylocation" name="company location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="locationOptions" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ Location  -->

            <!-- Sector -->
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company sector" vid="company-sector" rules="required">

                  <label> Company Sector<label class="text-danger">*</label></label>
                  <v-select v-model="companySector" name="company sector"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="companysectorList" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ Sector -->

            <!-- Type -->
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company type" vid="company-type" rules="required">

                  <label> Company Type<label class="text-danger">*</label></label>
                  <v-select v-model="companyType" name="company type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="companytypeList" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ Type -->

            <!-- Size -->
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company size" vid="company-size" rules="required">

                  <label> Company Size<label class="text-danger">*</label></label>
                  <v-select v-model="companySize" name="company size"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="sizeList" />
                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ Size -->

            <!-- website -->
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="company website" vid="company-website"
                  rules="required">

                  <label> Company Website<label class="text-danger">*</label></label>
                  <b-form-input name="company website" v-model="companyWeb" placeholder="Website address" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ website -->

            <!-- glassdoor -->
            <b-col md="6">
              <b-form-group>
                <validation-provider #default="{ errors }" name="glassdoor link" vid="glassdoor-link" rules="">
                  <label class="mb-50"> Glassdoor Link <label> </label></label>
                  <b-form-input name="glassdoor link" v-model="glassdoor" placeholder="https://glassdoor.co.in" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- glassdoor -->
          </b-row>
        </validation-observer>
      </tab-content>
      <!-- job details tab -->
      <tab-content title="Job Details" icon="fi fi-rr-record-vinyl" :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row class="match-height">
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Job Details
              </h5>
              <small class="text-muted">
                Enter the details of job roles and responsibilites.
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Job Title<label class="text-danger ml-25">*</label></label>
                <validation-provider #default="{ errors }" name="job title" rules="required">
                  <b-form-input v-model="jobTitle" name="job title" :state="errors.length > 0 ? false : null"
                    placeholder="e.g.Business Development Executive" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Job Function" rules="required">
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label>Job Function<label class="text-danger ml-25">*</label></label>
                  <v-select v-model="jobFunction" name="Job Function"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="jobFunctionList" label="title" />
                  <!-- :selectable="option => ! option.value.includes('select_value')" -->
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <label>CTC /Yearly Package (LPA)<label class="text-danger ml-25">*</label></label>
              <b-form-group>


                <div class="d-flex ">

                  <validation-provider #default="{ errors }" name="minimum yearly package" rules="required">
                    <b-form-input v-model="ctc_lower" name="min yearly package" class="flex-grow-1"
                      :state="errors.length > 0 ? false : null" placeholder="3.5" />
                    <!-- <cleave v-model="ctc_lower" class="form-control" name="min yearly package" :raw="false" :options="numberOptions"
                    placeholder="3.5" :state="errors.length > 0 ? false : null" /> -->
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                  <span class="mx-2 mt-1"> to </span>
                  <validation-provider #default="{ errors }" name="maximum yearly package" rules="required">
                    <b-form-input v-model="ctc_upper" name="maximum yearly package" class="flex-grow-1"
                      :state="errors.length > 0 ? false : null" placeholder="5.0" />
                    <!-- <cleave v-model="ctc_upper" class="form-control" name="minimum yearly package" :raw="false" :options="numberOptions"
                    placeholder="5.0" :state="errors.length > 0 ? false : null" /> -->
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                  <span class="ml-1 mt-1"> LPA </span>
                </div>
              </b-form-group>


            </b-col>

            <b-col md="6">
              <b-form-group>
                <label>Total Openings<label class="text-danger ml-25">*</label></label>
                <validation-provider #default="{ errors }" name="vacancies" rules="required|numeric">
                  <b-form-input v-model="vacancies" type="number" :state="errors.length > 0 ? false : null"
                    placeholder="22" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="Job Location" rules="required">
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label>Job Location<label class="text-danger ml-25">*</label></label>
                  <v-select v-model="jobLocation" name="Job Location" multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="jobLocationList" label="title" />
                  <!-- :selectable="option => ! option.value.includes('select_value')" -->
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" class="align-middle justify-content-center">
              <b-form-group class="mb-0 ml-50 align-middle mt-75">
                <!-- <label class="mb-1">Are you hiring immediately? </label> -->
                <b-form-checkbox v-model="immediateHiring" class="cursor-pointer">
                  Is this job opening an immediate hiring?
                </b-form-checkbox>

              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>About the Job Opportunity<label class="text-danger ml-25">*</label></label>
                <validation-provider #default="{ errors }" name="job opportunity" rules="required">
                  <b-form-textarea v-model="jobOpportunity" rows="4" placeholder="About Job Opportunity..."
                    :state="errors.length > 0 ? false : null" name="job opportunity" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <!-- {{ jobRoles }} -->
              <b-form-group id="responsibilitiesQuill">
                <label>Job Responsibilites<label class="text-danger ml-25">*</label></label>
                <validation-provider #default="{ errors }" name="job responsibilities" rules="required">

                  <quill-editor id="quil-content" v-model="jobRoles" name="job responsibilities" :options="editorOption"
                    :state="(errors.length > 0 || jobRoles == '<ul><li></li></ul>') ? false : null"
                    class="border-bottom-0 " :class="errors.length > 0 ? 'is-invalid' : null" />
                  <div id="quill-toolbar" class="d-flex justify-content-end border-top-0">
                    <!-- Add a bold button -->
                    <button class="ql-list" value="ordered" />
                    <button class="ql-list" value="bullet" />
                  </div>
                  <b-form-invalid-feedback
                    :state="(errors.length > 0 || jobRoles != '<ul><li></li></ul>') ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="skillQuill">
                <label>Required Skills<label class="text-danger ml-25">*</label></label>
                <validation-provider #default="{ errors }" name="required skills" rules="required">

                  <quill-editor id="quil-content" v-model="jobRequiredSkills" name="required skills"
                    :options="skilleditorOption" :state="errors.length > 0 ? false : null" class="border-bottom-0"
                    :class="errors.length > 0 ? 'is-invalid' : null" />
                  <div id="skill-quill-toolbar" class="d-flex justify-content-end border-top-0">
                    <!-- Add a bold button -->
                    <button class="ql-list" value="ordered" />
                    <button class="ql-list" value="bullet" />
                  </div>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content title="Eligibility Criteria" :before-change="validationFormInfo" icon="feather icon-file-text">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Eligibility Criteria
              </h5>
              <small class="text-muted">Enter the minimum eligibility criteria for candidates to apply.</small>
            </b-col>
            <b-col md="12">
              <b-form-group class="mb-2">
                <!-- <label class="mb-1">Are you hiring immediately? </label> -->
                <b-form-checkbox v-model="eligibility" class="cursor-pointer">
                  There is no minimum eligibility criteria
                </b-form-checkbox>

              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!eligibility">
            <b-col md="6">
              <validation-provider #default="{ errors }" name="eligibile courses" rules="">
                <b-form-group :state="errors.length > 0 ? false : null">
                  <label>Eligible courses</label>
                  <v-select v-model="eligibleCourses" name="Eligible Course" multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="courseList" label="title" />
                  <!-- :selectable="option => ! option.value.includes('select_value')" -->
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Course Percentage</label>
                <validation-provider #default="{ errors }" name="course percentage" rules="numeric">
                  <b-form-input v-model="coursePer" type="number" name="course percentage"
                    :state="errors.length > 0 ? false : null" placeholder="e.g. 77.6" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>Class 12th Percentage</label>
                <validation-provider #default="{ errors }" name="class 12th percentage" rules="numeric">
                  <b-form-input v-model="twelvePer" type="number" name="class 12th percentage"
                    :state="errors.length > 0 ? false : null" placeholder="e.g. 77.6" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>Class 10th Percentage</label>
                <validation-provider #default="{ errors }" name="class 10th percentage" rules="numeric">
                  <b-form-input v-model="tenthPer" type="number" name="class 10th percentage"
                    :state="errors.length > 0 ? false : null" placeholder="e.g. 77.6" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>Active backlogs allowed</label>
                <validation-provider #default="{ errors }" name="active backlogs" rules="numeric">
                  <b-form-input v-model="backlogs" type="number" name="active backlogs"
                    :state="errors.length > 0 ? false : null" placeholder="e.g. 2" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content title="Confirm" :before-change="validationFormAddress" icon="feather icon-file-text">
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                Confirm Details
              </h5>
              <small class="text-muted">Please verify the job details below</small>
            </b-col>
            <b-col cols="12" class="mb-2">

              <b-card class="card-app-design">
                <b-badge variant="light-primary">
                  {{ jobFunction }}
                </b-badge>
                <b-badge variant="light-warning" class="ml-2" v-if="immediateHiring">
                  Immediate Hiring
                </b-badge>
                <div class="d-flex my-2">
                  <b-avatar :src="selectedCompanyObject.logo" size="80" class="mr-1 avatar-border-2"
                    variant="transparent" />
                  <div>
                    <b-card-title class="mt-1 mb-75 text-uppercase">
                      {{ jobTitle }}
                    </b-card-title>
                    <b-card-sub-title class="font-small-3 mb-2">
                      {{ jobLocation[0] }}
                    </b-card-sub-title>
                  </div>

                </div>


                <div class="design-planning-wrapper">
                  <div class="design-planning mr-1 px-3">
                    <p class="card-text mb-25">
                      CTC (LPA)
                    </p>
                    <h6 class="mb-0">
                      ₹ {{ ctc_lower }} - {{ ctc_upper }}
                    </h6>
                  </div>
                  <div class="design-planning mr-1 px-3">
                    <p class="card-text mb-25">
                      Openings
                    </p>
                    <h6 class="mb-0">
                      {{ vacancies }}
                    </h6>
                  </div>

                </div>
                <div class="design-group">
                  <h6 class="section-label">
                    About Opportunity
                  </h6>

                  <b-card-text class="font-medium-1 mb-2 text-justify">
                    {{ jobOpportunity }}
                  </b-card-text>
                </div>
                <div class="design-group">
                  <h6 class="section-label">
                    Roles & Responsibilites
                  </h6>

                  <b-list-group>
                    <b-list-group-item class="d-flex mb-1" v-for="(item, index) in rolesList" :key="index"
                      style="border:none;padding:0;background-color: transparent;">
                      <u-icon icon="fi-sr-rec" class="mr-1 mt-50" size="8" />
                      <span class="text-justify" style="font-size:16px">{{ item }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div class="design-group">
                  <h6 class="section-label">
                    Required Skills
                  </h6>

                  <b-list-group>
                    <b-list-group-item class="d-flex mb-1" v-for="(item, index) in skillSetList" :key="index"
                      style="border:none;padding:0;background-color: transparent;">
                      <u-icon icon="fi-sr-rec" class="mr-1 mt-50" size="8" />
                      <span class="text-justify" style="font-size:16px">{{ item }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </div>


              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BFormTextarea, BFormCheckbox, BCard, BBadge, BCardTitle, BCardText,
  BAvatar, BButton, BCardSubTitle, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormFile,
  BInputGroup, BAlert, BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { avatarText } from "@core/utils/filter";
import { required, email, numeric, min, max } from '@validations'
// import { codeIcon } from './code'
import store from "@/store";
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import cityOptions from '../cityOptions'
import { quillEditor } from 'vue-quill-editor'
import courseOptions from "../coursesList"
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import firebase from "firebase/app";
import "firebase/storage";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    BCard, BBadge, BCardTitle, BCardText, BAvatar, BButton, BCardSubTitle,
    BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormFile, BInputGroup,
    BAlert, BListGroup, BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave,
    quillEditor,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      startIndex: 0,
      isPerSaved: false,
      isCompanySaved: false,
      recruiterCode: store.state.mainUserCodes.RecruiterCode,

      //personal data variables
      fullName: '',
      email: '',
      phone: '',
      designation: '',
      profileFile: null,
      uploadedUrl: '',
      avatar: '',
      rawFile: null,
      generalData: {},

      //company data variables
      companyName: '',
      companyDesc: '',
      companySector: '',
      companylocation: '',
      companySize: '',
      companyType: '',
      companyWeb: '',
      glassdoor: '',
      locationOptions: cityOptions,
      // recruiterCode: store.state.mainUserCodes.RecruiterCode,
      selectedRecruiterCode: '',
      selectedCompanyObject: {},
      companysectorList: [
        "Analytics",
        "Consulting",
        "Computer Science - Software - IT",
        "E-Commerce",
        "Engineering & Technology",
        "Finance/ BFSI",
        "FMCG",
        "Healthcare",
        "Media/Entertainment",
        "Research & Development",
        "Telecom",
        "Energy",
        "Manufacturing & Technology",
        "Others"
      ],
      sizeList: ["Below 10", "10-50", "50-100", "100-500", "Above 500"],
      companytypeList: [
        "Early Stage",
        "Established Startup",
        "Publicly Traded",
        "Unicorn",
        "Private Company"
      ],
      recruiterList: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Enter your Company Name",
        name: "company name"
      },
      limit: 10,
      filteredCompanies: [],
      rCodesList: [],

      //job data variables
      jobFunction: '',
      jobTitle: '',
      ctc_lower: '',
      ctc_upper: '',
      numberOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      vacancies: '',
      jobLocation: '',
      jobLocationList: ["Work from Home", ...cityOptions],
      jobOpportunity: '',
      jobRoles: '',
      jobRequiredSkills: '',
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'Enter about job roles and responsibilties',
      },
      skilleditorOption: {
        modules: {
          toolbar: '#skill-quill-toolbar',
        },
        placeholder: 'Enter about the required skills',
      },
      immediateHiring: false,
      eligibility: false,
      eligibleCourses: ["Any Graduate"],
      courseList: courseOptions,
      coursePer: '',
      tenthPer: '',
      twelvePer: '',
      backlogs: '',
      skillSetList: [],
      rolesList: [],

      required,
      email,
      numeric,
      min,
      max,
      jobFunctionList: [
        "Accounting",
        "Actuary",
        "Administration",
        "Advertising, Media & PR",
        "Architectural & Planning",
        "Business Development",
        "Community & Social Services",
        "Construction/Contracting",
        "Consulting",
        "Counselling",
        "Customer/ Technical Support",
        "Data & Analytics",
        "Design/ Art",
        "Education/ Teaching/Training",
        "Engineering-Civil/Mechanical/Other",
        "Engineering- Web/ Software",
        "Entrepreneurship",
        "Environmental/ Sustanability Management",
        "Finance",
        "Fundraising & Event Management",
        "General Management",
        "Healthcare Services",
        "Hotel/ Restaurant/ Hospitality",
        "Human Resources",
        "Information Technology",
        "Legal",
        "Library Science",
        "Logistics & Supply Chain",
        "Marketing- Brand Management",
        "Marketing- General",
        "Military & Protective Services",
        "Operations/ Production",
        "Political Organising/ Lobbying",
        "Product Management",
        "Product/Project Management",
        "Project Management",
        "Purchasing",
        "Quality Assurance",
        "Real Estate",
        "Research",
        "Sales",
        "Veterinary/ Animal Care",
        "Writing/ Editing",
        "others"
      ],

    }
  },

  created() {
    const thisIns = this
    firebase.firestore().collection("RecruiterCode").onSnapshot(function (snapshot) {
      // console.log(snapshot.size)
      snapshot.forEach(doc => {
        var obj = doc.data()
        obj.name = doc.data().CompanyName
        obj.code = doc.id
        thisIns.rCodesList.push(obj.code)
        thisIns.recruiterList.push(obj)

      })
    })
    var user = firebase.auth().currentUser
    firebase.firestore().collection("RecruiterId").doc(user.uid).get().then((doc) => {
      if (doc.exists) {
        thisIns.recruiterCode = doc.data().RecruiterCode
        thisIns.isPerSaved = doc.data().personalDetails ?? false
        thisIns.isCompanySaved = doc.data().companyDetails ?? false
        thisIns.generalData = doc.data()
        // console.log(thisIns)
        thisIns.setAllFields()
        if (thisIns.recruiterCode) {
          firebase.firestore().collection("RecruiterCode").doc(thisIns.recruiterCode).get().then((doc) => {
            var obj = doc.data()
            obj.name = doc.data().CompanyName
            obj.code = doc.id
            thisIns.selectedCompanyObject = obj
            thisIns.selectedRecruiterCode = obj.code
            console.log(`company code already exist ${thisIns.selectedRecruiterCode}`)
            thisIns.setData()
          })
          if (thisIns.isPerSaved) {
            thisIns.startIndex = 1
            // initializeTabs
            thisIns.$refs.jobformwizard.nextTab()
            console.log(thisIns.$refs.jobformwizard)
            if (thisIns.isCompanySaved) {
              thisIns.$refs.jobformwizard.nextTab()
              thisIns.startIndex = 2
            }
          }
          // if(this.isCompanySaved) this.startIndex = 3
        }
      }
    })
    console.log(store.state.mainUserCodes)

  },
  methods: {
    inputImageRenderer() {
      console.log('here rendering')
      const file = this.refInputEl.files[0]
      this.rawFile = file;
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          this.previewEl.localSrc = reader.result
          this.avatar = reader.result
          // console.log(this.previewEl)
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    setAllFields() {
      this.fullName = this.generalData.ContactName || this.generalData.displayName,
        this.email = this.generalData.email
      this.designation = this.generalData.Designation
      this.phone = this.generalData.ContactNo
      this.avatar = this.generalData.profilephoto
      this.uploadedUrl = this.generalData.profilephoto

      //setting companyData

    },
    setData() {
      this.companyName = this.selectedCompanyObject.name
      this.companyDesc = this.selectedCompanyObject.CompanyDescription
      this.companylocation = this.selectedCompanyObject.CompanyLocation
      this.companyWeb = this.selectedCompanyObject.CompanyWebsite
      this.companySector = this.selectedCompanyObject.CompanySector
      this.companyType = this.selectedCompanyObject.CompanyType
      this.companySize = this.selectedCompanyObject.CompanySize
      this.glassdoor = this.selectedCompanyObject.glassdoor ?? ''
    },
    onSelected(option) {
      console.log(option)
      this.selectedCompanyObject = option.item
      this.selectedRecruiterCode = this.selectedCompanyObject.code

      this.setData()
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredArr = this.recruiterList.filter(item => {
        if (item.name) {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        }
      }).slice(0, this.limit)
      this.filteredCompanies = [{
        data: filteredArr,
      }]
    },
    async uploadFile(rawFile) {
      var uid = firebase.auth().currentUser.uid
      var result = await new Promise(async (resolve) => {
        var storageRef = {}
        storageRef = firebase.storage().ref(`Recruiter/profilephoto/` + uid + rawFile.name);
        const thisIns = this
        await storageRef.put(rawFile).then(function (result) {
          //  console.log(result)
          storageRef.getDownloadURL().then(function (url) {
            var img = url;
            // console.log(url)
            thisIns.uploadedUrl = img
            resolve(thisIns.uploadedUrl)

          })

        });
      })
      return result;
    },
    async saveCompanyDetails() {

      var user = firebase.auth().currentUser;
      return new Promise((resolve, reject) => {

        console.log(this.selectedRecruiterCode)

        if (this.selectedRecruiterCode) {
          firebase.firestore().collection("RecruiterId").doc(user.uid).update({
            RecruiterCode: this.selectedRecruiterCode,
            companyDetails: true,
            CompanyName: this.companyName,
            CompanySector: this.companySector,
            CompanyLocation: this.companylocation,
          }).then(() => {
            firebase.firestore().collection("RecruiterCode").doc(this.selectedRecruiterCode).set({
              CompanyName: this.companyName,
              CompanyDescription: this.companyDesc,
              CompanyLocation: this.companylocation,
              CompanyWebsite: this.companyWeb,
              CompanySector: this.companySector,
              CompanyType: this.companyType,
              CompanySize: this.companySize,
              glassdoor: this.glassdoor,
            }, { merge: true }).then(() => {
              this.$store.dispatch("updateMainUserCodes", {
                RecruiterCode: this.selectedRecruiterCode
              });
              console.log('updated company Data data')
              // }
              this.recruiterCode = this.selectedRecruiterCode
              resolve(this.recruiterCode)
            })
          }).catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong!',
                icon: 'fi-rr-exclamation',
                text: `${err.message}`,
                variant: 'danger',
              },
            })
          });
        } else {
          //register the code first

          let generatedCode = this.randomString(10, this.rCodesList);
          console.log('hi we are generating code')
          firebase.firestore().collection("RecruiterId").doc(user.uid).update({
            RecruiterCode: generatedCode,
            companyDetails: true,
            CompanyName: this.companyName,
            CompanySector: this.companySector,
            CompanyLocation: this.companylocation,
          }).then(() => {
            firebase.firestore().collection("RecruiterCode").doc(generatedCode).set({
              CompanyName: this.companyName,
              CompanyDescription: this.companyDesc,
              CompanyLocation: this.companylocation,
              CompanyWebsite: this.companyWeb,
              CompanySector: this.companySector,
              CompanyType: this.companyType,
              CompanySize: this.companySize,
              glassdoor: this.glassdoor,
              timestamp: firebase.firestore.FieldValue.serverTimestamp()
            }, { merge: true }).then(() => {
              firebase
                .firestore()
                .collection("Codes")
                .doc("Value")
                .update({
                  RecruiterCode: firebase.firestore.FieldValue.arrayUnion(generatedCode)
                });
              this.$store.dispatch("updateMainUserCodes", {
                RecruiterCode: generatedCode
              });
              this.selectedRecruiterCode = generatedCode
              console.log('updated data by generating code')
              this.recruiterCode = this.selectedRecruiterCode
              // }
              resolve(this.recruiterCode)

            })
          }).catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong!',
                icon: 'fi-rr-exclamation',
                text: `${err.message}`,
                variant: 'danger',
              },
            })
          });
        }
      })
    },
    async formSubmitted() {
      var user = firebase.auth().currentUser
      if (!this.isPerSaved) {
        //save personal Details
        if (this.rawFile) {
          await this.uploadFile(this.rawFile)
        }
        firebase.firestore().collection("RecruiterId").doc(user.uid).set({
          email: this.email,
          ContactName: this.fullName,
          Designation: this.designation,
          profilephoto: this.uploadedUrl,
          personalDetails: true
        }, { merge: true }).then(() => {
          // if(this.rawFile){
          store.dispatch("auth/updateUserInfo", {
            username: this.fullName,
            displayName: this.fullName,
            profilephoto: this.uploadedUrl,
            avatar: this.uploadedUrl,

          });
          console.log('updated data')
        })
      }
      if (!this.isCompanySaved) {
        //save companyDetails
        var recCode = await this.saveCompanyDetails();
        console.log(`this is recruiter code from company save function ${recCode}`)
        this.saveJobDetails(recCode)


      } else {
        if (this.recruiterCode) {
          console.log(`this is existing recruiter code ${this.recruiterCode}`)
          this.saveJobDetails(this.recruiterCode)
        }
      }

    },
    saveJobDetails(recCode) {
      let user = firebase.auth().currentUser;
      var studentCode = store.state.mainUserCodes.StudentCode
      if (this.backlogs === "") {
        this.backlogs = "9999";
      }


      firebase.firestore().collection("RecruiterJobs").add({
        RecruiterId: user.uid,
        RecruiterCode: recCode,
        JobTitle: this.jobTitle,
        JobFunction: this.jobFunction,
        ctc_lower: this.ctc_lower,
        ctc_upper: this.ctc_upper,
        category: "On-Campus",
        vacancies: this.vacancies,
        location: this.jobLocation,
        CGPA: this.coursePer,
        Xth: this.tenthPer,
        XIIth: this.twelvePer,
        Status: 'Active',
        CourseName: this.eligibleCourses,
        CompanyName: this.companyName,
        Colleges: studentCode,
        eligibility: this.eligibility,
        description: this.jobOpportunity,
        ////****------------changing logo object after adding avataar----------------**** */
        logo: this.selectedCompanyObject.logo,
        Backlogs: this.backlogs,
        CompanySize: this.companySize,
        CompanyLocation: this.companylocation,
        isUrgent: this.immediateHiring,
        Priority: 7,
        Opportunities: this.jobOpportunity,
        SkillsList: this.skillSetList,
        Roles: this.rolesList,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()


      }).then(docref => {
        firebase
          .firestore()
          .collection("RecruiterId")
          .doc(user.uid)
          .set({
            activeJobs: firebase.firestore.FieldValue.arrayUnion(
              docref.id
            )
          },
            { merge: true }
          );
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Job Posted',
            icon: 'fi-rr-exclamation',
            text: '👋 Your new Job Opening posted successfully.',
            variant: 'success',
          },
        })
      }).catch((err) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong!',
            icon: 'fi-rr-exclamation',
            text: `${err.message}`,
            variant: 'danger',
          },
        })
      });;

    },
    validationPersonalForm() {
      if (this.isPerSaved) {
        return true
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.personalRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }
      // return true;
    },
    validationCompanyForm() {
      if (this.isCompanySaved) {
        return true
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.companyRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      }
      // return true;
    },
    validationForm() {

      var arr = this.jobRequiredSkills.split("<li>")
      arr.forEach((item) => {
        var str = item.replace("<li>", "").replace("</li>", "").replace("<ul>", "").replace("</ul>", "")
        if (str.length > 0) {
          this.skillSetList.push(str.replace(/(<([^>]+)>)/ig, ''))
        }
      })
      var arr2 = this.jobRoles.split("<li>")
      arr2.forEach((item) => {
        var str = item.replace("<li>", "").replace("</li>", "").replace("<ul>", "").replace("</ul>", "")
        if (str.length > 0) {
          this.rolesList.push(str.replace(/(<([^>]+)>)/ig, ''))
        }
      })
      // console.log(rolesList)
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
      // return true;
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    randomString(len, arr) {
      let charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var randomString = "";
      for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      if (arr.includes(randomString)) return this.randomString(len, arr);
      else return randomString;
    },
    avatarText
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    return {
      refInputEl,
      previewEl,
      // inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#responsibilitiesQuill {
  .is-invalid ::v-deep {
    border-color: #D23A3A;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D23A3A' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D23A3A' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    background-position: right calc(0.3625em + 0.219rem) center;

    >.ql-container {
      border-color: #D23A3A !important;

    }

    +#quill-toolbar {
      border-color: #D23A3A !important;

    }
  }
}

#skillQuill {
  .is-invalid ::v-deep {
    border-color: #D23A3A;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D23A3A' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D23A3A' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
    background-position: right calc(0.3625em + 0.219rem) center;

    >.ql-container {
      border-color: #D23A3A !important;

    }

    +#skill-quill-toolbar {
      border-color: #D23A3A !important;

    }
  }
}

#quil-content ::v-deep {

  >.ql-container {
    border-bottom: 0;

  }

  +#quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  +#skill-quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
